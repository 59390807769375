<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />

    <v-snackbar v-model="showSnackBar" color="#000" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> {{ dataType }} </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4>
      <v-flex xs12 lg10>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout wrap justify-start>
            <v-flex xs12 sm12 text-left px-2>
              <!-- <span class="itemValue">{{ dataType }} </span> -->
              <v-text-field
                ref="name"
                required
                class="formClass"
                label="City"
                :rules="[rules.required]"
                dense
                v-model="pincodeData.name"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-flex xs12 sm12 text-left px-2>
            <v-combobox
              required
              clearable
              v-model="pincodeData.pincodes"
              hide-selected
              multiple
              label="Please type and press enter"
              persistent-hint
              small-chips
            ></v-combobox>
          </v-flex>

          <v-layout wrap justify-center>
            <v-flex xs6 sm4 xl3 px-2>
              <v-btn
                block
                tile
                outlined
                color="#FF1313"
                light
                :ripple="false"
                depressed
                @click="closeDialoge"
                class="itemValue"
              >
                Cancel
              </v-btn>
            </v-flex>
            <v-flex xs6 sm4 xl3 px-2>
              <v-btn
                block
                tile
                color="#000"
                light
                :ripple="false"
                depressed
                :disabled="!valid"
                @click="validate"
                class="itemValue"
              >
                <!-- <span style="color: #fff" v-if="storage">Edit {{ dataType }}</span> -->
                <span style="color: #fff">SAVE</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage", "dataType", "url"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      pincodeData: {
        name: null,
        pincodes: [],
      },
      valid: true,
      rules: {
        required: (value) => !!value || " Required Field",
      },
    };
  },
  beforeMount() {
    if (this.storage) {
      this.pincodeData.name = this.storage.name;
      this.pincodeData.pincodes = this.storage.pincodes;
      this.pincodeData.id = this.storage._id;
    }
  },
  methods: {
    closeDialoge() {
      var type;
      if (this.storage) type = "Edit";
      else type = "Add";
      this.$emit("stepper", {
        getData: false,
        type: type,
        dialog:false,
        editUser: false,
      });
    },

    validate() {
      if (this.$refs.form.validate()) this.updateData();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    updateData() {
      axios({
        method: "post",
        url: this.url,
        data: this.pincodeData,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.showSnackBar = true;
             var type;
      if (this.storage) type = "Edit";
      else type = "Add";
            this.$emit("stepper", {
              type: type,
              storage: this.storage._id,
              msg: response.data.msg,
              getData: true,
              dialog: false,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>