<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showSnackBar" color="#000" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog
      v-model="confirmDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' ||
        $vuetify.breakpoint.name == 'sm' ||
        $vuetify.breakpoint.name == 'md'
          ? '100vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '40vw'
          : '50vw'
      "
    >
      <v-card outlined class="pa-4">
        <v-layout wrap justify-center pa-2>
          <v-flex xs12 pt-4>
            <span>Are you Sure want to upload the Sheet?</span>
          </v-flex>
          <v-flex xs12 pt-4>
            <v-layout wrap justify-center>
              <v-flex xs6 sm4 xl3 px-2>
                <v-btn
                  block
                  tile
                  outlined
                  color="#FF1313"
                  light
                  :ripple="false"
                  depressed
                  @click="confirmDialog = false"
                  class="itemValue"
                >
                  Cancel
                </v-btn>
              </v-flex>
              <v-flex xs6 sm4 xl3 px-2>
                <v-btn
                  block
                  tile
                  color="#000"
                  light
                  :ripple="false"
                  depressed
                  @click="uploadPincode"
                  class="itemValue"
                >
                  <!-- <span style="color: #fff" v-if="storage">Edit {{ dataType }}</span> -->
                  <span style="color: #fff">SAVE</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="addDataDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' ||
        $vuetify.breakpoint.name == 'sm' ||
        $vuetify.breakpoint.name == 'md'
          ? '100vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '40vw'
          : '50vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <AddorUpdateData
              :dataType="'Pincode'"
              :url="'/pincode/add'"
              @stepper="winStepper"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' ||
        $vuetify.breakpoint.name == 'sm' ||
        $vuetify.breakpoint.name == 'md'
          ? '100vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '40vw'
          : '50vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <AddorUpdateData
              v-bind:storage="editPincodeData"
              @stepper="winStepper"
              :key="editPincodeData._id"
              :dataType="'Pincode'"
              :url="'/pincode/edit'"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-start pt-3 pl-2 pr-5>
      <v-flex align-self-center xs12 sm5 md7 lg4 xl6 text-left>
        <span class="itemHeading1"> Pincodes </span>
      </v-flex>
      <v-flex text-right lg3 xl2>
        <v-btn
          block
          tile
          depressed
          outlined
          color="#000000"
          :ripple="false"
          class="itemValue"
          :href="mediaURL + '1626340508087.xlsx'"
        >
          Download Template
        </v-btn>
      </v-flex>
      <v-flex align-self-center xs6 sm4 md3 lg3 xl2 px-3>
        <span>
          <v-btn
            block
            tile
            depressed
            outlined
            id="Documents"
            @click="$refs.browseFile.click()"
            color="#000000"
            :ripple="false"
            class="itemValue"
          >
            UPLOAD SPREAD SHEET
            <v-icon>mdi-upload</v-icon>
          </v-btn>

          <input
            v-show="false"
            ref="browseFile"
            accept=".pdf, .xls, .xlsx"
            type="file"
            @change="browseFile"
          />
        </span>
      </v-flex>
      <v-flex align-self-center xs6 sm3 md2 lg2 xl2 px-3>
        <v-btn
          block
          tile
          depressed
          outlined
          color="#000000"
          :ripple="false"
          @click="addDataDialog = true"
          class="itemValue"
        >
          Add New Data
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-start pa-4>
      <template v-for="(item, i) in pincodes">
        <v-flex xs12 lg6 xl6 pa-2 :key="i">
          <Pincode
            :deleteUrl="'/pincode/remove'"
            v-bind:storage="item"
            :dataType="'Pincodes'"
            @stepper="winStepper"
          />
        </v-flex>
      </template>
      <v-flex xs12 v-if="pincodes.length < 1">
        <span class="itemKey"> No data available </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex xs12>
        <v-pagination
          v-model="currentPage"
          :length="pages"
          :total-visible="7"
          color="#919191"
        ></v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import Pincode from "./pincode";
import AddorUpdateData from "./addorupdateData";
export default {
  components: {
    Pincode,
    AddorUpdateData,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 3000,
      confirmDialog: false,
      msg: null,
      //   users: [],
      pages: 0,
      count: 20,
      currentPage: this.$route.query.page ? Number(this.$route.query.page) : 1,
      addDataDialog: false,
      pincodeFile: null,
      pincodes: [],
      editDialog: false,
      editPincodeData: {},
    };
  },

  beforeMount() {
    this.getData();
  },
  methods: {
    // uploadDocuments(e) {
    //   var files = e.target.files || e.dataTransfer.files;
    //   this.pincodeFile = files[0];
    // },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/pincode/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.pincodes = response.data.data;
              this.appLoading = false;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    browseFile(e) {
      // var files = e.target.files || e.dataTransfer.files;
      this.pincodeFile = e.target.files[0];
      //   if(this.pincodeFile)
      // {
      //     setTimeout(5000)

      //   this.uploadPincode();
      // }
      this.uploadPincode();
    },
    uploadPincode() {
      let formData = new FormData();
      formData.append("doc", this.pincodeFile);
      this.appLoading = true;
      axios({
        method: "post",
        url: "/pincode/excelimport/upload",
        data: formData,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        if (response.data.status) {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.getData();
        } else {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
    },
    winStepper(windowData) {
      if (windowData.msg) {
        this.showSnackBar = true;
        this.msg = windowData.msg;
      }
      if (windowData.type == "Add") this.addDataDialog = false;
      else if (windowData.type == "Edit") {
        if (windowData.pincodeData)
          this.editPincodeData = windowData.pincodeData;
        if (windowData.getData) this.getData();
        this.editDialog = windowData.dialog;
      } else if (windowData.type == "Delete") {
        if (windowData.pageResponse.status) {
          this.msg = " Data Deleted Successfully";
          this.showSnackBar = true;
          if (windowData.getData) this.getData();
        } else {
          this.msg = " Data cannot be Deleted";
          this.showSnackBar = true;
        }
      }
    },
  },
};
</script>
